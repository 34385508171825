// @flow
import React from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import type { PageLayoutMdl, PremiumSignUpPageMdl } from '../../cms/models/pages';
import PageLayout from '../../components/PageLayout';
import Layout from '../../components/Layout';
import { getLayoutPageProps } from '../../pages';
import WebsiteData, { getWebsiteDataFromProps } from '../../components/WebsiteData';
import type { WebsiteMdl } from '../../cms/models/website';
import { getMetaTagsFromWebsite } from '../../components/Layout/components/MetaTagsWrapper';
import { getDirectDestination } from '../../components/Website/components/LandingPage';
import PageContext from '../../components/Website/context/PageContext';

const getPageLayout = (premiumPage: PremiumSignUpPageMdl): PageLayoutMdl => {
  return get(premiumPage, 'page[0]');
};

type Props = {
  data: {
    website: WebsiteMdl,
    page: PremiumSignUpPageMdl,
  },
};

const PremiumTrialPage = (props: Props) => {
  const { data } = props;
  const { page } = data;
  const pageLayout = getPageLayout(page);
  const website: WebsiteMdl = get(props, 'data.website');
  console.log('page', page);
  return (
    <Layout {...getLayoutPageProps(props)} metaTags={getMetaTagsFromWebsite(website)}>
      <WebsiteData website={getWebsiteDataFromProps(props)}>
        <PageContext.Provider
          value={{
            directDestination: getDirectDestination(page),
          }}
        >
          <PageLayout pageLayout={pageLayout} />
        </PageContext.Provider>
      </WebsiteData>
    </Layout>
  );
};

export const query = graphql`
  query PremiumTrialPage($websiteSlug: String!, $locale: String!, $id: String!) {
    page: contentfulPremiumSignUpPage(id: { eq: $id }) {
      ...PremiumSignUpPage
    }
    website: contentfulWebsite(slug: { eq: $websiteSlug }, node_locale: { eq: $locale }) {
      ...Website
    }
  }
`;

export default PremiumTrialPage;
